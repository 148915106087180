<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form class="form-box" ref="form" :rules="rules" :model="form" v-loading="loading" label-position="right" label-suffix=":" label-width="100px" size="large" element-loading-text="数据加载中">
                <el-form-item label="活动标题" prop="activityTitle">
                    <el-input type="text" auto-complete="off" v-model="form.activitytitle" placeholder="请输入活动标题色值"></el-input>
                </el-form-item>
                <el-form-item label="活动简介" prop="activityDesc">
                    <el-input type="text" auto-complete="off" v-model="form.activitydesc" placeholder="请输入活动简介色值"></el-input>
                </el-form-item>
                <el-form-item label="活动字体" prop="activityFont">
                    <el-input type="text" auto-complete="off" v-model="form.activityfont" placeholder="请输入活动字体"></el-input>
                </el-form-item>
                <el-form-item label="tab选中" prop="tabSelect">
                    <el-input type="text" auto-complete="off" v-model="form.tabselect" placeholder="请输入tab选中色值"></el-input>
                </el-form-item>
                <el-form-item label="tab未选中" prop="tabUnselect">
                    <el-input type="text" auto-complete="off" v-model="form.tabunselect" placeholder="请输入tab未选中色值"></el-input>
                </el-form-item>
                <el-form-item label="tab字体" prop="tabFont">
                    <el-input type="text" auto-complete="off" v-model="form.tabfont" placeholder="请输入tab字体"></el-input>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" @click="save">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb
    },
    data() {
        return {
            menuList: ['设置', 'app配色'],
            loading: false,
            header: {},
            form: {
            },
            rules: {
                activitytitle: [{ required: true, message: '活动标题不能为空', trigger: 'blur' }],
                activitydesc: [{ required: true, message: '活动简介不能为空', trigger: 'blur' }],
                activityfont: [{ required: true, message: '活动字体不能为空', trigger: ['blur', 'change'] }],
                tabselect: [{ required: true, message: 'tab选中不能为空', trigger: ['blur', 'change'] }],
                tabunselect: [{ required: true, message: 'tab未选中不能为空', trigger: 'blur' }],
                tabfont: [{ required: true, message: 'tab字体不能为空', trigger: 'blur' }],
            },
            value: ['1', '3'],
            storeInfo: {},

        };
    },
    created() {
        this.loadData()
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        // this.submitForm()
    },
    methods: {
        //获取app配色
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/system/color/matching/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.form = ret.data;
                    console.log(22222, this.form)
                    // this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 保存app配色
        save() {
            let _this = this
            this.$refs.form.validate(valid => {
                if (valid) {
                    request.post('/system/color/matching/edit', this.form).then(ret => {
                        if (ret.code == 1) {
                            this.form = ret.data;
                            console.log(22222, this.form)
                            this.loadData()
                            this.$message.success('成功');
                        } else {
                            this.$message.error(ret.msg);
                        }
                    });

                } else {
                    this.$message.error('请补全信息');
                    return false;
                }
            });
        }
    }
};
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader1 .el-upload {
    border: 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 150;
    height: 150px;
}

.thumb {
    width: 150;
    height: 150px;
}
</style>